import { EnvInitializer } from './types';

const TAG_MANAGER_INITIALIZER: EnvInitializer = {
  'prod': {
    gtmId: 'GTM-W3KM7K4',
  },
  'test': {
    gtmId: 'GTM-W3KM7K4',
    auth: 'VdopC9yXv4KifEzrp6rmQw',
    preview: 'env-4',
  },
  'qa': {
    gtmId: 'GTM-W3KM7K4',
    auth: 'VdopC9yXv4KifEzrp6rmQw',
    preview: 'env-4',
  },
  'acc': {
    gtmId: 'GTM-W3KM7K4',
    auth: 'VdopC9yXv4KifEzrp6rmQw',
    preview: 'env-4',
  }
}

const STOCK_STATUS_MAP = {
  CompletelyOnStock: 'in stock',
  NothingOnStock: 'available on date',
  Unavailable: 'discontinued'
}

export { TAG_MANAGER_INITIALIZER, STOCK_STATUS_MAP }